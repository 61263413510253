<template>
  <div v-if="isMobileAndTelegramApp" class="container">
    <div class="mute-audio" @click="isMute = !isMute">
      <svg v-if="isMute" t="1715409481715" class="icon mute" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5712" width="128" height="128"><path d="M1012.992 602.496l-90.496 90.496L832 602.496l-90.496 90.496-90.496-90.496L741.504 512l-90.496-90.496 90.496-90.56L832 421.504l90.496-90.56 90.496 90.56L922.496 512l90.496 90.496zM192 768c-106.048 0-192-86.016-192-192v-128a192 192 0 0 1 192-192h64L576 0v1024L256 768H192z m256 0V256l-128 128H192a64 64 0 0 0-64 64v128c0 35.392 28.608 64 64 64h128l128 128z" fill="currentColor" p-id="5713"></path></svg>
      <svg v-else t="1715409507225" class="icon not-mute" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5874" width="128" height="128"><path d="M640 1007.232v-134.72C788.8 819.648 896 679.04 896 512c0-166.912-107.2-307.584-256-360.448V16.768C860.672 73.728 1024 273.536 1024 512c0 238.528-163.328 438.336-384 495.232zM192 768c-106.048 0-192-86.016-192-192v-128a192 192 0 0 1 192-192h64L576 0v1024L256 768H192z m256 0V256l-128 128H192a64 64 0 0 0-64 64v128c0 35.392 28.608 64 64 64h128l128 128z m384-256c0 119.104-81.728 218.368-192 246.912v-136.64c38.144-22.208 64-63.04 64-110.272s-25.856-88.064-64-110.272v-136.64c110.272 28.48 192 127.744 192 246.912z" fill="currentColor" p-id="5875"></path></svg>
    </div>

    <div class="count">
      {{ count }}
    </div>

    <div class="wooden-fish-wrap">
      <svg ref="icon" t="1715344716532" class="icon scaleUpDown" viewBox="0 0 1365 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2002" width="128" height="128" @click="click"><path fill="#fff" d="M1.450653 780.39695c-10.175905 64.255398 36.031662 101.161718 59.626108 112.361614 23.594445 11.178562 63.274073 0 78.825927 0 116.542907 11.178562 366.759228 131.220103 678.606972 131.220103 0 0 504.635269 7.445264 543.31224-360.487287 9.19458-95.529771 4.885288-277.458732-71.039334-286.162651-63.956734-8.426588-102.121709 4.074628-183.315615 20.565141-53.908828 10.922564-189.011561 29.973052-212.926004 44.970245-260.989553 118.718887-403.324219 204.371417-442.299853 217.128631-29.439724 0-54.975485-7.359931-62.100752-69.972677 0-25.706426 98.089747-87.039184 140.137353-96.959091C682.660267 452.869354 796.365867 435.333519 809.720409 435.333519c19.263819 0 441.489194-101.588381 454.438406-111.188291 12.949212-9.59991 26.62375-18.986489 26.623751-52.543508 0-15.359856-33.813016-49.663534-72.319322-91.455142-45.674238-49.556869-99.94573-107.092329-140.606682-120.788201C1002.934597 20.958737 856.077308-10.912964 727.779844 3.572233 446.929143 35.273269 271.677453 342.662388 256.424263 363.995521c-64.852725 90.708483-116.542907 205.587406-143.678653 256.296264C86.548522 669.272659 11.71189 735.149375 1.450653 780.39695z" p-id="2003"></path></svg>

      <div class="auto-area">
        <vue-button-toggle
          v-model="value"
          bg-active-color="#0071FF"
          bg-deactive-color="#787880"
          dot-active-color="#FFFFFF"
          dot-deactive-color="#FFFFFF"
        >
        </vue-button-toggle>
        Auto
      </div>
    </div>

    <audio ref="muyuSound" src="/click.mp3"></audio>
  </div>
  <div v-else class="not-tg">
    Please use telegram mobile version
  </div>
</template>

<script>
import VueButtonToggle from "vue-button-toggle"
import { throttle } from 'throttle-debounce';
import querystring from 'querystring'
import axios from 'axios'

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // 检查设备是否为移动设备的常见标识符
    return /android|iphone|ipad|ipod|iemobile|wpdesktop/i.test(userAgent.toLowerCase());
}

export default {
  name: 'Home',
  components: {
    VueButtonToggle
  },
  data () {
    return {
      value: false,
      count: 0,
      timer: null,
      isMobileAndTelegramApp: true, // isMobileDevice() && !!Telegram.WebApp.initData,
      isMute: Number(localStorage.getItem('isMute')) === 1,
      initData: '',
      axiosTimer: null,
      clickCount: 0
    }
  },
  watch: {
    value (value) {
      if (value) {
        this.setAuto()
      } else {
        this.clearAuto()
      }
    },
    isMute (value) {
      localStorage.setItem('isMute', value ? 1 : 0)
    },
    count (value) {
      if (value >= 3000) {
        this.value = false
      }
    }
  },
  mounted () {
    // this.isMobileAndTelegramApp = isMobileDevice() && !!Telegram.WebApp.initData
    this.initData = decodeURIComponent(Telegram.WebApp.initData)
    this.axiosTimer = setInterval(() => {
      this.submitInfo(this.clickCount)
      this.clickCount = 0
    }, 5000)
  },
  methods: {
    submitInfo (c) {
      const initData = querystring.parse(this.initData)
      if (initData.user) {
        initData.user = JSON.parse(initData.user)
      } else {
        initData.user = {}
      }

      if (c > 0) {
        const data = {
          uid: initData.user.id,
          points: c,
          datas: this.initData
        }
        axios({
          url: 'https://app.woodenfish.info/api/user/knock',
          method: 'POST',
          data,
          params: data
        }).then(res => {
          if (Number(res.data.status) === 3007) {
            Telegram.WebApp.showAlert(`You've reached the hourly limit. Please come back to pray in the next hour`)
            this.value = false
          }
        }).catch(err => {
          Telegram.WebApp.showAlert(err.message || err.msg || 'Unknow Error')
        })
      }
    },
    throttleClick (e) {
      throttle(1000, () => {
        this.click(e)
      })
    },
    setAuto () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      this.timer = setInterval(() => {
        this.click()
      }, 1000)
    },
    clearAuto () {
      this.timer && clearInterval(this.timer)
      this.timer = null
    },
    click: throttle(0, function (e) {
      const icon = this.$refs.icon
      icon.style.animation = 'none';
      const timer = setTimeout(() => {
        icon.style.animation = 'scaleUpDown 0.2s ease';
        this.createFloatingText(e)
      }, 1);
    }),
    createFloatingText: throttle(200, function (e) {
      if (this.count >= 3000) {
        Telegram.WebApp.showAlert('Please try again after a short break')
        this.value = false
        return
      }
      this.$refs.muyuSound.muted = this.isMute
      this.$refs.muyuSound.currentTime = 0
      const t = setTimeout(() => {
        this.$refs.muyuSound.play()
        this.count++
        this.clickCount++
        let x
        let y
        if (!e) {
          x = window.screen.width / 2 - 30
          y = window.screen.height / 2
        } else {
          x = e.pageX
          y = e.pageY
        }
        const text = document.createElement('div');
        text.classList.add('floating-text');
        text.textContent = 'Pray +1!'; // 这里可以根据需要更改文字内容
        text.style.left = `${x}px`;
        text.style.top = `${y}px`;
        document.body.appendChild(text);

        // 设定文字在创建后2秒渐隐消失
        const timer = setTimeout(() => {
          text.classList.add('hidden');
          // 确保动画完成后从DOM中移除文字
          text.remove()
          clearTimeout(timer)
        }, 2000);
        clearTimeout(t)
      }, 1)
    })
  }
}
</script>

<style scoped lang="scss">
@import '../sass/var.scss';
.not-tg {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 20px;
}

.mute-audio {
  position: fixed;
  z-index: 0;
  right: 24px;
  top: 32px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 80px 16px 80px;
  .count {
    color: $textColor;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
  }

  .wooden-fish-wrap {
    svg {
      width: 240px;
      height: 240px;
    }
  }

  .auto-area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
  }
}
</style>
